// General stuff
.home-banner{
  @media screen and (min-width:768px){
    min-height: 600px;
  }
}

.vsa-item__trigger__content{

}

.swiper-slide{
  opacity: 0.25 !important;
}

.swiper-slide-active{
  opacity: 1 !important;
}
.swiper-button-next,.swiper-button-prev{
&:after{
  font-size: 16px !important;
}
}


.swiper-slide-prev{
  opacity: 0.50 !important;
}
.swiper-slide-next{
  opacity: 0.50 !important;
}

.vsa-item__content{
  background-color: #f7fafc;
    padding: 20px 10px;

}

.vsa-list button{
width:100%;

}

.vsa-item__trigger{
  display: flex;
  position: relative;
  justify-content: center;
  width:100%;
  padding: 20px 10px;
  border:solid 1px #A5ACAF;
  text-align: left;
}

.vsa-item__trigger__icon{
  position: relative;
    width: 50px;
      height: 30px;
}

.vsa-item__trigger__icon{

  &:after{
  content: '';
  display: block;
  width: 30px;
  position: absolute;
  right:0px;
  height: 30px;
  background-image: url('/assets/static/arrow.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
&--is-active{

    transform: rotate(180deg);

}
}
