//
// Voting
//

.cc-v-up,
.cc-v-down {
    position: relative;
    padding: 0 5px;
    margin: 0 4px;
    color: #656c7a;
    text-indent: -9999px;
    overflow: hidden;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;

    &::after {
        content: '';
        width: 10px;
        height: 11px;
        display: block;
        opacity: 0.7;
        min-height: 12px;
        background-repeat: no-repeat;
        transition: 0.3s ease;
    }

    &:hover:not(.disabled)::after {
        opacity: 1;
    }

    &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
    }
}

.cc-v-down::before {
    content: '';
    border-left: 2px solid #e7e9ee;
    height: 12px;
    position: absolute;
    top: 0;
    left: -5px;
    cursor: default;
    pointer-events: none;
}

.cc-v-up::after  {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'%3E%3C/path%3E%3C/svg%3E");
}

.cc-v-down::after  {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
}