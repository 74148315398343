// Effects
/*effect-underline*/
a.effect-underline {
  border: none;
  position: relative;
}
a.effect-underline:after {
  content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 7px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}
a.effect-underline--grey:after {
  border-bottom: $mid-grey 1px solid;
}
a.effect-underline:hover:after {
  opacity: 1;
  transform: scale(1);
}


.bg-circle{

}

.bg-circle:before{
margin-left: -30px;
margin-bottom:-15px;
  transform-origin: 340px center;
animation: circle 3s infinite linear;
}
@keyframes circle {
	to { transform: rotate(1turn); }
}
