/**
 * Basic typography style for copy text
 */
 // FONT CLASSES

 @import url(https://fonts.googleapis.com/css?family=Montserrat);

%font-sans {
  font-family: $regular-font-stack;
  font-weight: 300;
  font-style: normal;
  @include alias;
}


%font-alt {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 400;
  @include alias;
}


body {
  background: #fff;
  color: $text-color;
  @extend %font-sans;
  @include font-values(16,16);
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  hyphens: none;
}

h1 {
  @extend %font-sans;
  @include fluid-type(2.5rem, 3rem);
  font-weight: 800;
  line-height: 1;
}

h2 {
  @extend %font-sans;
  @include fluid-type(1.7rem, 2.8rem);
  font-weight: 800;
}

h3 {
  @extend %font-sans;
  @include fluid-type(1.2rem, 1.5rem);
  line-height: 1.5;
  font-weight: 500;
}

h4 {
  @extend %font-sans;
  @include fluid-type(1rem, 1.2rem);
  font-weight: 400;
}

h5 {
  @extend %font-sans;
  @include fluid-type(1rem, 1rem);
  font-weight: 400;
  color: #BABEC8;
  line-height: 1.5
}

h6{
  @extend %font-sans;
  @include fluid-type(0.8rem, 0.8rem);
  font-weight: 400;
}

.small {
  @include font-values(14,17);
}
.medium {
  @include font-values(16,18);
  line-height: 1.3;
}
.big {
  @include font-values(24,27);
  line-height: 1.3;
}
.huge {
  @include font-values(27,30);
  line-height: 1.3;
}
p {
  @include fluid-type(14px, 16px);
  margin-top: 0;
  font-weight: 200;
  line-height: 1.5;
  margin-bottom: 0.7rem;

  b, strong{
    color: #222427;
  }
}
